<template>
    <div class="">
      <v-layout row wrap class="d-flex">
        <v-flex xs12 md12 lg12 class="pa-5">
          <h1 class="f-alfa primary--text">
            <router-link to="/"><v-icon class="primary--text mr-5">mdi-arrow-left</v-icon>Home</router-link> / {{ $route.params.id }}
          </h1>
        </v-flex>
        <v-flex xs12 md12 lg8 class="pa-5">
          <h2 class="mb-5 primary--text">Edit Booking</h2>
          <v-simple-table>
            <tbody>
             
              <tr class="pa-2">
                <th class="fs-12 primary--text">Name</th>
                <th class="fs-12 pa-2"><v-text-field v-model="booking.name" single-line outlined></v-text-field></th>
              </tr>
              <tr class="pa-2">
                <th class="fs-12 primary--text">Email</th>
                <th class="fs-12 pa-2"><v-text-field v-model="booking.email" single-line outlined></v-text-field></th>
              </tr>
              <tr class="pa-2">
                <th class="fs-12 primary--text">Phone</th>
                <th class="fs-12 pa-2"><v-text-field v-model="booking.mobile_number" single-line outlined></v-text-field></th>
              </tr>
              <tr class="pa-2">
                <th class="fs-12 primary--text">WhatsApp</th>
                <th class="fs-12 pa-2"><v-text-field v-model="booking.whatsapp" single-line outlined></v-text-field></th>
              </tr>
              <tr>
                <th></th>
                <th class="pa-2 text-right"><v-btn class="primary black--text" x-large @click="editBooking">Save</v-btn></th>
              </tr>
            </tbody>
          </v-simple-table>
          <h2 class="mt-5 primary--text">Pre-flight data</h2>
          <v-simple-table class="mt-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-left uppercase primary--text">Name</th>
                  <th class="text-left uppercase primary--text">Age</th>
                  <th class="text-left uppercase primary--text">Weight(Kg)</th>
                  <th class="text-left uppercase primary--text">Height(Cm)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td></td>
                    <td class="pt-2 pb-2"><v-text-field single-line outlined label="Full Name" v-model="name"></v-text-field></td>
                    <td class="pt-2 pb-2"><v-text-field single-line outlined label="Age" v-model="age"></v-text-field></td>
                    <td class="pt-2 pb-2"><v-text-field single-line outlined label="Weight (Kg)" v-model="weight"></v-text-field></td>
                    <td class="pt-2 pb-2"><v-text-field single-line outlined label="Height (Cm)" v-model="height"></v-text-field></td>
                    <td class="pt-2 pb-2"><v-btn class="primary black--text" x-large @click="savePreFlight">Save</v-btn></td>  
                </tr>
                <tr v-for="(item,index) in booking.pre_flight_data" :key="item.createdAt">
                  <td>{{ index + 1 }}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.age}}</td>
                  <td>{{item.weight}}</td>
                  <td>{{item.height}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
        <!-- <v-flex xs12 md4 class="pa-5">
          <h2 class="mb-5 primary--text">Payment Details</h2>
          <v-simple-table v-for="(payment,index) in booking.payment_info" :key="index">
            <tbody v-if="payment.paymentResponse.status == 'SETTLED'">
              <tr>
                <th class="fs-12 primary--text">Merchant Order No</th>
                <th class="fs-12">{{ payment.paymentResponse.merchantOrderNo }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">PayBy Order No</th>
                <th class="fs-12">{{ payment.paymentResponse.orderNo }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Status</th>
                <th class="fs-12">{{ payment.paymentResponse.status }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Paid Amount</th>
                <th class="fs-12">{{ payment.paymentResponse.paymentInfo.paidAmount.amount }} {{ payment.paymentResponse.paymentInfo.paidAmount.currency }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Fee</th>
                <th class="fs-12">{{ payment.paymentResponse.paymentInfo.payeeFeeAmount.amount }} {{ payment.paymentResponse.paymentInfo.payeeFeeAmount.currency }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Settlement Amount</th>
                <th class="fs-12">{{ payment.paymentResponse.paymentInfo.settlementAmount.amount }} {{ payment.paymentResponse.paymentInfo.settlementAmount.currency }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Card</th>
                <th class="fs-12 pa-5">
                  <p class="pa-0 ma-0">{{ payment.paymentResponse.paymentInfo.cardInfo.first6.slice(0,4) }} **** **** {{ payment.paymentResponse.paymentInfo.cardInfo.last4 }}</p>
                  <p class="pa-0 ma-0">{{ payment.paymentResponse.paymentInfo.cardInfo.brand }}</p>
                  <p class="pa-0 ma-0">Type - {{ payment.paymentResponse.paymentInfo.cardInfo.cardType }}</p>
                  <p class="pa-0 ma-0">Issue Country - {{ payment.paymentResponse.paymentInfo.cardInfo.issueCountry }}</p>
                </th>
              </tr>
            </tbody>
          </v-simple-table>
        </v-flex> -->
      </v-layout>
    </div>
  </template>
  
    <script>
  import { BASE_URL } from "../../config";
  import Axios from "axios";
  export default {
    data() {
      return {
        booking: {},
        name : "",
        age : "",
        height : "",
        weight : ""
      };
    },
    mounted() {
      this.fetchBookingDetails();
    },
    methods: {
      async editBooking(){
        let payload = {
          name : this.booking.name,
          mobile_number : this.booking.mobile_number,
          whatsapp : this.booking.whatsapp,
          email : this.booking.email
        }
        this.$toastr.s("Booking updated successfully", "Booking Updated");
      },
      async savePreFlight() {
        if(this.name == "" || this.age == "" || this.height == ""){
          this.$toastr.e("Please enter Name, Age and Height to add pre-flight data", "Missing fields");
        }
        const headers = {
          Authorization:
            "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
        };
        let url = BASE_URL + '/booking/pre-flight-data/'+this.$route.params.id
        let payload = {
          name : this.name,
          age : this.age,
          height : this.height,
          weight : this.weight
        }
        let {data} = await Axios.post(url,payload)
        this.fetchBookingDetails()
      },
      async fetchBookingDetails() {
        const headers = {
          Authorization:
            "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
        };
        let url = BASE_URL + "/booking/" + this.$route.params.id;
        let { data } = await Axios.get(url, { headers });
        this.booking = data[0];
      },
    },
  };
  </script>
  
  <style scoped>
  .fs-12 {
    font-size: 12pt !important;
  }
  </style>
  
  